import { userAction } from './user'
import { authorizedLogin } from './isLoggedIn'
import { extractSlug } from './slug'

const slug = extractSlug()
export const loginToken = async (jwtToken) => {
  try {
    const userConexia = await userAction('GETBYLOGINCONEXIA', { jwtToken, slug })
    return authorizedLogin(userConexia)
  } catch (error) {
    return `Authentication failed: ${error.message}`        
  }
}