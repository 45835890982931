import React, { useEffect, useState } from 'react'
import Structure from './site-structure'
import { choiceClassroom, getCurrentUser, setUserSession } from '../services/isLoggedIn'
import { classroomAction } from '../services/classroom'
import Header from './header'
import { readFile } from '../api'

function PTResume () {
  const [user, setUser] = useState(null)
  const [classrooms, setClassrooms] = useState([])
  useEffect(() =>{ 
    const fetchData = async () => {
      const user = getCurrentUser()
      setUser(user)
      const classrooms = await classroomAction('GETBYUSER', user)
      setClassrooms(classrooms)
    }
    const url = readFile('assets/img/bg.png');
    document.body.style.backgroundImage = `url(${url})`
    fetchData()
  }, [])

  const onSubmit = async (e, classroom) => {
    e.preventDefault()
    const redirect = await choiceClassroom(user, classroom, '')
    setUserSession('userPT', JSON.stringify(user))
    if (!redirect) return
    setTimeout(() => { 
      window.location.href = redirect
     }, 1)
  }

  return (
    <Structure>
      <Header/>
      <div className="row m-t-16">
        <div className="col-sm-11 m-auto">
            <div className="text-center m-t-10">
                <div>
                    <h1 className="tblue t2 m-t-20 m-b-10">Precisamos identificar a sua Turma</h1>
                </div>
            </div>
        </div>
      </div>
      
        <div className="row mt3">
          {classrooms.map(classroom => (
            <div className="col-2 m-auto text-center" key={`flex-classroom-${classroom._id}`}>              
              <input type="button" onClick={(e) => onSubmit(e, classroom)} value={classroom.name} className="btn-big grad-mains" />
            </div>
          ))}
        </div>
      
    </Structure>
  )
}
export default PTResume
