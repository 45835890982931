import React, { useState } from 'react'
import { debounce } from 'lodash'

export default function Send({ 
  onClick, 
  className, 
  isEmpty = true, 
  children,
  disabled,
  loading,
  ...restProps 
}) {
  const [clicked, setClicked] = useState(false)
  const click = debounce(() => { 
    setClicked(true)
    onClick()
    setTimeout(() => setClicked(false), 10000)
  }, 400)
  const label = clicked ? 'submitting' : !isEmpty ? 'submit' : 'skip'
  
  return (
    <div key="button">
      <div className="d-flex justify-content-center bd-highlight mb-3 m-t-20">
        <div id="bt_next">
          <input
            type="button"
            disabled={disabled || clicked}
            onClick={click}
            value={!loading ? label : ''}
            className={className}
            {...restProps}
          />
          {loading && <div className="btn-loading"></div>}
        </div>
        {children}
      </div>
    </div>
  );
}



