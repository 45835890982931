import React, { Component } from 'react'
import StudentResultBase from '../components-pt/student-result-base'
import { reportAction } from '../services/report'
import { readFile } from '../api'

export default class StudentsResults extends Component {
  constructor(props) {
    super(props) 
      this.state = {
        test:[]
      }           
  }  

  componentDidMount() {
    this.loadData()                 
  }

  loadData = async () => {
    const report = await reportAction("GETREPORTBYUSER", this.props.match.params.id)
    this.setState({ test: report.test })
  
    document.body.style.backgroundImage = `url('${readFile('assets/img/fundoresults.png')}')`
    document.body.style.backgroundColor ="#dcf5ff94"   
  } 
   
  render(){
    return <StudentResultBase test={this.state.test} />
  }
}
