import React, { Component } from 'react'
import { Api as axios, adminConfig } from '../api'
import PTAnswerBox from './pt-answerbox'
import { saveTest } from '../services/test'
import Countdown from './countdown'
import QuestionBack from './questionback'
import Button from './send'

export default class PTGrammarA extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitting: false,
      isEmpty: true,
      choices: [],
      chuncks: [],
      values: '',
    }
  }

  componentDidMount() {
    axios.get(`questions/${this.props.id}`)
      .then(response => {
        const { data } = response
        for(let i=0; i < data.choices.length; i++) {
          const choiceshuffled = data.choices[i].optionlist.concat(data.choices[i].word)
            .map(value => ({ sort: Math.random(), value }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value)
          data.choices[i].optionlist = choiceshuffled
        }

        this.setState({
          ptmodel: data.ptmodel,
          name: data.name,
          order: data.order,
          modelname: data.modelname,
          level: data.level,
          timer: data.timer,
          sentence_type: data.sentence_type,
          sentence: data.sentence,
          choices: data.choices,
          grammar: data.grammar,
          chuncks: data.chuncks, 
          background:data.background          
        }) 
      })
      .catch(function (error) { 
        if (error.response?.status === 404) window.location.href ='/404'
        console.error(error) 
      })
  } 

  onChange = (e) => {       
    e.preventDefault()

    let { choices } = this.state
    let { name: index_choices, value: value_selected } = e.target
    choices[index_choices].answer = value_selected

    this.setState({ 
      choices, 
      isEmpty: choices.filter(choice => !choice.answer ||choice.answer === '').length > 0 
    })    
  }

  onSave = async () => {
    const { submitting, choices } = this.state
    if (submitting) return
    this.setState({ submitting: true }, async () => {
      const redirect = await saveTest(this, 'Grammar', choices)
      if (redirect) window.location.href = redirect
    })
  }

  onSubmit = (e) => e.preventDefault()

  render() {    
    const { background, timer, sentence, values, choices, chuncks, isEmpty } = this.state
    const backgroundColor = background && background !== '' && adminConfig().root + "/back/ga/" + background

    return (      
      <form className="cont" onSubmit={this.onSubmit}>
        <Countdown
          questionId={this.props.id}
          timer={timer}
          color="toptimer white t-orange" 
          colortext="bottimer txt-orange"
          next={this.onSave} 
        />

        <PTAnswerBox 
          sentence={sentence}
          values={values}
          choices={choices}
          chuncks={chuncks} 
          onChange={this.onChange} 
        />

        <QuestionBack src={backgroundColor}/>  

        <Button 
          className="btn-big gradient-orange" 
          onClick={this.onSave} 
          isEmpty={isEmpty} 
        />
      </form>
    )
  }
}
