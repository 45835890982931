import { baseSlugURL } from './slug'
import { Api as axios, readFile } from '../api'

const BASE_SLUG_URL = baseSlugURL()
const baseURL = `warmup`

const warmupBackground = (layout) => {   
  document.body.style.backgroundImage = (layout && layout.backgroundImage) || ''
}

export const warmup = (path) => {
  const sliptedPath = path.split('/')[3]
  const next_model = parseInt(sliptedPath, 10) + 1
  let route = `/warmup/${next_model || 9}`    
  if (next_model > 10) route = '/start'
  else if (next_model === 0) route = '/teste-dispositivo'
  // eslint-disable-next-line no-undef
  return `${BASE_SLUG_URL}${route}`
}

export const warmupLayout = (order) => { 
  let layout = {}
  switch(order) {
    case '0': 
      layout = {
        bolal:'bola bdb-b b-l',
        bolar:'bola bdb-b b-r',
        cordar:'corda bd-b cr-r',
        cordal:'corda bd-b cr-l',
        color:'tl-1 cl-b',
      }    
      break
    case '1':
      layout = {
        bolal:'bola bdb-g b-l',
        bolar:'bola bdb-g b-r',
        cordar:'corda bd-g cr-r',
        cordal:'corda bd-g cr-l',
        color:'tl-1 cl-g1',
        model: 'Speaking',
        icon: readFile('assets/img/iconspeaking.png'),
        subtitle: 'Escute o áudio e selecione a alternativa que melhor condiz ao enunciado. Então, grave sua pronúncia lendo em voz alta a alternativa selecionada. Você pode ouvir o enunciado novamente quantas vezes precisar, enquanto houver tempo. Também pode ouvir o que você gravou, e gravar novamente caso esteja insatisfeito com sua resposta.',
        backgroundImage: `url('${readFile('assets/img/fundoverde.png')}')`
      }  
      break
    case '2':
    case '3':
      layout = {
        bolal:'bola bdb-g b-l',
        bolar:'bola bdb-g b-r',
        cordar:'corda bd-g cr-r',
        cordal:'corda bd-g cr-l',
        color:'tl-1 cl-g1',
        model: 'Speaking',
        icon: readFile('assets/img/iconspeaking.png'),
        subtitle: 'Grave sua pronúncia lendo em voz alta a frase abaixo, mesmo que seja um pergunta. Você pode ouvir o que você gravou, e gravar novamente caso esteja insatisfeito com sua resposta.',
        backgroundImage: `url('${readFile('assets/img/fundoverde.png')}')`
      }  
      break
    case '4': // listening a     
      layout = {
        bolal:'bola bdb-b b-l',
        bolar:'bola bdb-b b-r',
        cordar:'corda bd-b cr-r',
        cordal:'corda bd-b cr-l',
        color:'tl-1 cl-b',
        model: 'Listening',
        icon: readFile('assets/img/redfone.png'),
        subtitle: 'Escute o áudio e selecione a alternativa que melhor condiz ao enunciado. Você pode ouvir novamente quantas vezes precisar, enquanto houver tempo.',
        backgroundImage: `url('${readFile('assets/img/fundoazul.png')}')`
      }
      break
    case '5':
      layout = { 
        bolal:'bola bdb-b b-l',
        bolar:'bola bdb-b b-r',
        cordar:'corda bd-b cr-r',
        cordal:'corda bd-b cr-l',
        color:'tl-1 cl-b',
        model: 'Listening',
        icon: readFile('assets/img/redfone.png'),
        subtitle: 'Escute o áudio e selecione a imagem que melhor condiz ao enunciado. Você pode ouvir novamente quantas vezes precisar, enquanto houver tempo.',
        backgroundImage: `url('${readFile('assets/img/fundoazul.png')}')`
      }  
      break
    case '6':
      layout = {
        bolal:'bola bdb-o b-l',
        bolar:'bola bdb-o b-r',
        cordar:'corda bd-o  cr-r',
        cordal:'corda bd-o  cr-l',
        color:'tl-1 cl-o',
        model: 'Reading',
        icon: readFile('assets/img/nota.png'),
        subtitle: 'Leia o enunciado e selecione a imagem correspondente.',
        backgroundImage: `url('${readFile('assets/img/backpink.png')}')`
      }  
      break
    case '7':
      layout = {
        bolal:'bola bdb-o b-l',
        bolar:'bola bdb-o b-r',
        cordar:'corda bd-o  cr-r',
        cordal:'corda bd-o  cr-l',
        color:'tl-1 cl-o',
        model: 'Reading',
        icon: readFile('assets/img/nota.png'),
        subtitle: 'Leia o enunciado e selecione a alternativa correspondente.',
        backgroundImage: `url('${readFile('assets/img/backpink.png')}')`
      }  
      break
    case '8':
      layout = {
        bolal:'bola bdb-o b-l',
        bolar:'bola bdb-o b-r',
        cordar:'corda bd-o  cr-r',
        cordal:'corda bd-o  cr-l',
        color:'tl-1 cl-o',
        model: 'Grammar',
        icon: readFile('assets/img/nota.png'),
        subtitle: 'Complete a frase selecionando a opção correta de cada caixinha.',
        backgroundImage: `url('${readFile('assets/img/backpink.png')}')`
      }  
      break
    case '9':
      layout = {
        bolal:'bola bdb-p b-l',
        bolar:'bola bdb-p b-r',
        cordar:'corda bd-p cr-r',
        cordal:'corda bd-p cr-l',
        color:'tl-1 cl-p',
        model: 'Writing',
        icon: readFile('img/iconwriting.png'),
        subtitle: 'Escute o enunciado, e transcreva exatamente o que ouviu. Você pode ouvir novamente quantas vezes precisar, enquanto houver tempo.',
        backgroundImage: `url('${readFile('assets/img/fundoroxo.png')}')`
      }  
      break
    case '10':
      layout = {
        bolal:'bola bdb-p b-l',
        bolar:'bola bdb-p b-r',
        cordar:'corda bd-p cr-r',
        cordal:'corda bd-p cr-l',
        color:'tl-1 cl-p',
        model: 'Writing',
        icon: readFile('assets/img/iconwriting.png'),
        subtitle: 'Leia o enunciado e responda a pergunta abaixo. Use frases completas em sua resposta.',
        backgroundImage: `url('${readFile('assets/img/fundoroxo.png')}')`
      }  
      break
    
    default:
      break
  }
  
  warmupBackground(layout) 
  return layout
}

export const warmupAction = async(action) => {
  switch(action) {
    case 'GET': 
      const warmup = await axios.get(`${baseURL}/`)
      if (!warmup || !warmup.data) return

      return warmup.data
    default:
      return
  }
}