import React from 'react'

export default function QuestionBack ({ src }){
  if (!src || src.indexOf("undefined") > -1) return <div />

  return  (
    <div>
      <img alt="" className="img-fluid back" src={src} />
    </div>
  )
}


