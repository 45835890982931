
import React, { Component } from 'react'
import { clearSession, getCurrentUser } from '../services/isLoggedIn'

export default class Whois extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      user: {}
    }
  }
  
  componentDidMount() {
    this.load()
  }

  load = () => {
    const user = getCurrentUser()
    this.setState({ user })
  }

  onClearSession = async (data) => {
    const redirect = await clearSession(data)
    if (!redirect) return
    setTimeout(() => { window.location.href = redirect }, 2)
  }
  
  render () {
    const { user } = this.state
    const { match } = this.props    
    const { slug } = match.params

    return (
      <>
        <h1>Você é {user?.name}?</h1>
        <div className="row d-flex justify-content-center">
          <div className="col">
            <a onClick={() => onClearSession("userPT")} className="btn-big gradient-red">
            NÃO
            </a>
          </div>
          <div className="col">
            <a href={`/${slug}/teste-dispositivo`} className="btn-big gradient-blue">
            SIM
            </a>
          </div>
        </div>
      </>
    )
  }
}