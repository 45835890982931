import React, { Component } from 'react'
import PTAudio from './pt-audio'
import PTImages from './pt-images'
import { adminConfig, Api as axios, readFile } from '../api'
import { saveTest } from '../services/test'
import Countdown from './countdown'
import Button from './send'

export default class PTListeningA extends Component {
  constructor(props) {
    super(props)

    this.state = { 
      submitting: false,
      isEmpty: true,      
      values: '',        
      audio: '',
      choices: []
    }
  }

  componentDidMount() {
    axios.get(`questions/${this.props.id}`)
      .then(response => {
        const { data } = response
        const [files] = data?.choices || []
        const newfiles = files.optionlist?.map(name => ({ 
          blob: adminConfig().root + '/la/' + name,
          name,
          file: null
        }))    

        const audio = {
          blob: adminConfig().root + '/la/' + data.sentence,
          name: data.sentence,
          file: null
        }

        this.setState({
          ptmodel: data.ptmodel,
          name: data.name,
          order: data.order,
          modelname: data.modelname,
          level: data.level,
          timer: data.timer,
          sentence_type: data.sentence_type,
          sentence: data.sentence,
          choices: data.choices,
          grammar: data.grammar,
          files: newfiles,
          audio
        })
    })
    .catch(function (error) { 
      if (error.response?.status === 404) window.location.href ='/404'
      console.error(error) 
    })
  }

  onChangeValues = (values) => this.setState({ values, isEmpty: false })

  onSave = async () => {
    const { submitting,values } = this.state
    if (submitting) return
    this.setState({ submitting: true }, async () => {
      const redirect = await saveTest(this, 'Listening', values)
      if (redirect) window.location.href = redirect
    })
  }

  onSubmit = (e) => e.preventDefault()

  render() {
    const { values, timer, files, audio, isEmpty } = this.state
    return (    
      <div>
        <Countdown
          questionId={this.props.id}
          timer={timer}
          color="toptimer white t-blue"
          colortext="bottimer txt-blue"
          next={this.onSave}
        />
        
        <form className="m-t-20" onSubmit={this.onSubmit}>                    
          <PTImages
            class="b-blue"
            files={files}
            values={values}
            onChangeValues={this.onChangeValues}
          />

          <PTAudio
            playImage={readFile('assets/img/play_arrow.png')}
            replayImage={readFile('assets/img/replay.png')}
            audio={audio}
          />

          <Button
            className="btn-big gradient-blue"
            onClick={this.onSave}
            isEmpty={isEmpty}
          />
        </form> 
      </div>
    )
  }
}
