/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

const WIDTH_100 = { width: '100%' }
const SCREENS_ON_SHOW_TERMS = ['login', 'start']

function FooterGlobal() {
  const path = window.location.pathname
  const [screen] = path.split('/').reverse()
  
  return (
    <footer 
      style={{ 
      width: '60%', 
      paddingBottom: 20, 
      display: 'flex', 
      justifyItems: 'center',
      alignItems: 'center',
      margin: '0 auto', 
      textAlign: 'center' 
      }}
      className="footerGlobal"
    >
      {SCREENS_ON_SHOW_TERMS.includes(screen) &&      
        <>
          <ul style={{ display: 'flex', width: '60%', marginBottom: 0 }}>
            <li style={WIDTH_100}>
              <a target="_blank" href="https://link.adv.co/TermosdeUsoFlexAssessment">Termos de Uso</a>
            </li>
            <li style={WIDTH_100}>
              <a target="_blank" href="https://link.adv.co/PoliticadePrivFlexAssessment">Política de Privacidade</a>
            </li>
          </ul>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            width: '40%',
          }}>
            <a target="_blank" href="https://corelab.com.br" >
              <img src={'/img/corelab-logo.svg'} width='180px'/>
            </a>
          </div>
        </>
      }
    </footer>
  )
}

export default FooterGlobal
