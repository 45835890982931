import React from 'react'
import Recording from '../components/Recording'

function Multiplechoicerecord ({ isRecording, choices, start, stop }) {
  return ( 
    <div className="row">
      {choices.map((choices, key) => (
        <div key={key} className="col-md-11 m-auto ">
          <div className="m-b-20">
            <div className="form-group ">
              {choices.optionlist.map((text, key) => (
                <div key={"opt_" + text} className="options d-flex justify-content-center m-t-5"  onClick={() => start(key)} id={"play_" + key} >   
                  <div className="stm1" > {text} </div>                                     
                </div>                                
              ))}
              <div onClick={stop} id="stop" className="none" >
                <Recording 
                  isRecording={isRecording} 
                  onFinished={stop} 
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
export default Multiplechoicerecord
