import React, { Component } from 'react'
import { adminConfig, Api as axios, readFile } from '../api'
import PTAudio from './pt-audio'
import Button from './send'
import { saveTest } from '../services/test'
import Countdown from './countdown'
import QuestionBack from './questionback'

export default class PTWritingA extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitting: false,
      isEmpty: true,
      choices: [],
      value: '',
      audio:''
    }
  }

  componentDidMount() {
    axios
      .get(`questions/${this.props.id}`)
      .then((response) => {
        const { data } = response
        const audio = {
          blob: adminConfig().root + "/wr/"+ data.sentence,
          name: data.sentence,
          file: null
        }

        this.setState({
          ptmodel: data.ptmodel,
          name: data.name,
          order: data.order,
          modelname: data.modelname,
          level: data.level,
          timer: data.timer,
          sentence_type: data.sentence_type,
          sentence: data.sentence,
          choices: data.choices,
          grammar: data.grammar,
          background: data.background,
          audio,
        })
    })
    .catch(function (error) { 
      if (error.response?.status === 404) window.location.href ='/404'
      console.error(error) 
    })
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({ 
      [e.target.name]: e.target.value,
      isEmpty: false
    })
  }

  correctAnswer = (values) => this.setState({ values, isEmpty: false })

  onSave = async () => {
    const { submitting } = this.state
    if (submitting) return
    this.setState({ submitting: true }, async () => {
      const redirect = await saveTest(this, 'Writing')
      if (redirect) window.location.href = redirect
    })
  }

  onSubmit = (e) => e.preventDefault()

  render() {
    const { timer, value, background, isEmpty, audio } = this.state
    const { enunciado2 } = this.props 
    const backgroundColor = background && background !== '' && adminConfig().root + "/back/wa/" + background

    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <Countdown
            questionId={this.props.id}
            timer={timer}
            color="toptimer white t-purple"
            colortext="bottimer txt-purple" 
            next={this.onSave}
          />

          <div className="row">
            <div className="col-sm-8 m-auto">
              <div className="form-group">
                <label className="m-b-10 f-18">{enunciado2}</label>
                <textarea 
                  rows="8" 
                  cols="50" 
                  name="value" 
                  className="form-control purple-shadow" 
                  value={value} 
                  onChange={this.onChange}
                  autoComplete="off"
                >
                  It was a dark and stormy night...
                </textarea>
              </div>
            </div>
          </div>

          <PTAudio 
            playImage={readFile('assets/img/play_arrow.png')} 
            replayImage={readFile('assets/img/replaywriting.png')}
            audio={audio}
          />

          <Button 
            className="btn-big gradient-purple"
            onClick={this.onSave}
            isEmpty={isEmpty} 
          />

          <QuestionBack src={backgroundColor}/>  
        </form>
      </div>
    )
  }
}
