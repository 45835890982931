import React from 'react'

function PTQuestion ({ sentence }) {
  return (
    <div className="col-sm-9 m-auto">
      <div className="st2">{sentence}</div>
    </div>
  )
}
export default PTQuestion
