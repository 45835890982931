import LogRocket from 'logrocket'
import sign from 'jwt-encode'
import { Api as axios } from '../api'
import { userAction } from './user'
import { classroomAction } from './classroom'
import { baseSlugURL, adminURL, extractSlug } from './slug'

const BASE_SLUG_URL = baseSlugURL()
const SLUG = extractSlug()
const BASE_ADMIN_URL = adminURL()

const TEST_WEIGHT = { 
  positive: 0,
  negative: 0,
  positive_count: 0,
  negative_count: 0 
}

export const setUserSession = (name, value) => {
  window.sessionStorage.setItem(name, value)
}

export const removeUserSession = (name) => {
  window.sessionStorage.removeItem(name)
}

export const getUserSession = (origin) => {  
  const user = window.sessionStorage.getItem(origin)
  return (user) && JSON.parse(window.sessionStorage.getItem(origin))   
}

export const getCurrentUser = () => {  
  const userPT = getUserSession('userPT')
  const userAdmin = getUserSession('userAdmin')
  return userPT ? userPT : userAdmin ? userAdmin : null
}

export const isLoggedIn = () => {
  const userAdmin = getUserSession('userAdmin')
  const userPT = getUserSession('userPT')

  const isLoggedInAdmin = userAdmin ? userAdmin.isLoggedIn : false
  const isLoggedInPT  = userPT ? userPT.isLoggedIn : false
  const { pathname: currentPage } = window.location
  const isAdminPage   = currentPage.split('/').filter(f=> f === 'admin')

  return { isLoggedInAdmin, isLoggedInPT, currentPage, isAdminPage }
}

export const clearSession = async (origin) => {
  removeUserSession('userAdmin')
  removeUserSession('userPT')
  const { redirect } = await userAction('LOGOUT', { origin, baseUrl: BASE_SLUG_URL })
  return redirect
}

export const authorizedLogin = async user => {
  if(!user) return 'Login failed.'
  if (typeof user === 'string') return user

  let redirect = ''
  user.isLoggedIn = true  
  if(user.profile === 'STUDENT') {           
    const classroom = await classroomAction('GETBYUSER', user)
    if(classroom.length <= 0) return 'User does not have a classroom.'

    LogRocket.identify(user._id, {
      name: user.name,
      email: user.email,
      subscriptionType: SLUG
    })
    redirect = (classroom.length === 1) ? 
    await choiceClassroom(user, classroom[0], redirect) : 
    `${BASE_SLUG_URL}/choice-classroom/`
    
    setUserSession('userPT', JSON.stringify(user))
  } else {
    setUserSession('userAdmin', JSON.stringify(user))
    const jwt = sign(JSON.stringify(user), 'SECRET')
    redirect = `${BASE_ADMIN_URL}/${SLUG}/auth?accessToken=${jwt}`
  }

  setTimeout(() => { window.location.href = redirect }, 1)
  return null
}

export const choiceClassroom = async(user, classroom, redirect) => {
  user.classroom = classroom._id
  redirect = `${BASE_SLUG_URL}/student-result/${user._id}`
  
  if (!user.block) {
    user.test_weight = TEST_WEIGHT
    user.testID = ''
    redirect = `${BASE_SLUG_URL}/intro-video/`

    const test = await axios.get(`test/user/${user._id}`) 
    if(test && test.data.length > 0) {
      user.testID = test.data[0]._id
      redirect = `${BASE_SLUG_URL}/resume/`
    }          
  }    
  
  return redirect
}

export const login = async (login, password) => {
  const user = await userAction('GETBYLOGIN', { login, password })
  return authorizedLogin(user)
}
