import React, { Component } from 'react'
import PTGrammarA from './pt-grammar-a'
import PTGrammarB from './pt-grammar-b'
import PTListeningA from './pt-listening-a'
import PTListeningB from './pt-listening-b'
import PTReadingA from './pt-reading-a'
import PTReadingB from './pt-reading-b'
import PTWritingA from './pt-writing-a'
import PTWritingB from './pt-writing-b'
import PTSpeakingA from './pt-speaking-a'
import PTSpeakingB from './pt-speaking-b'
import Structure from "./site-structure"
import { questionLayout } from "../services/question"


const TYPES = {
  'grammar-a': PTGrammarA,
  'grammar-b': PTGrammarB,
  'reading-a': PTReadingA,
  'reading-b': PTReadingB,
  'listening-a': PTListeningA,
  'listening-b': PTListeningB,
  'writing-a': PTWritingA,
  'writing-b': PTWritingB,
  'speaking-a': PTSpeakingA,
  'speaking-b': PTSpeakingB,
}

export default class Question extends Component {
  constructor(props) {
    super(props)
    this.state = {
      layout: {
        model: '',
        icon: ''
      } 
    } 
  }

  componentDidMount() {
    const { match } = this.props
    const layout = questionLayout(match.params.model)  
    this.setState({ layout })

    document.body.style.backgroundImage = layout.backgroundImage
    document.body.style.backgroundColor = layout.background
  }

  render() { 
    const { layout } = this.state
    const { match } = this.props
    const { question, position } = match.params

    const Component = TYPES[layout?.model]

    return ( 
      <Structure>
        <div className="text-center m-t-20"> 
          <div className="text1">teste</div>   
          <div className={layout.color}> {layout.name} </div>                 
          <div className={layout.cordal}>
            <div className={layout.bolal}></div>
          </div>
          <div className={layout.cordar}>
            <div className={layout.bolar}></div>
          </div>
          <div className="row"> 
            <div className="card col-sm-12 sha m-t-20">
              <div className="card-body">
                <img className="w-70" alt="" src={layout.icon}/>
                <div className="st3">{layout.subtitle}</div>
                <div>
                  {Component && <Component id={question} position={position}/>}                                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </Structure>
    )
  }
}
