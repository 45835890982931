import React, { useState } from 'react'

function Button({ src, children: label, oncePlay }) {
  const onPlay = () => {
    const button = document.getElementById("play")
    if (button) button.play()
    if (oncePlay) oncePlay(true)
  }

  return (
    <div onClick={onPlay}>
      <img alt="" className="mx-81 cursor" src={src} />
      <div>
        <label>{label}</label>
      </div>  
    </div>
  )
}

function PTAudio({ audio, playImage, replayImage }) {
  const [play, setPlay] = useState(false)
  if (!audio) return <div />
  
  const [image, label] = play ? 
    [replayImage, 'Ouvir novamente'] : 
    [playImage, 'Ouvir áudio']

  return (
    <div className="row justify-content-center" key={`ad_${audio.name}`}>
      <audio id="play" controls={false} src={audio.blob}>
        Your browser does not support the <code>audio</code> element.
      </audio>

      <Button 
        src={image} 
        oncePlay={setPlay}
      >
        {label}
      </Button>          
    </div>
  )
}

export default PTAudio
