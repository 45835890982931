/* eslint-disable import/named */
/* eslint-disable import/no-named-as-default-member */
import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import { withRouter } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLifeRing } from '@fortawesome/free-solid-svg-icons'
import FreshdeskWidget from '@personare/react-freshdesk-widget'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import './css/font.css'
import './css/style.css'
import './css/rwd-table.min.css'
import PTHome from './components-pt/home'
import PTLogin from './components-pt/login'
import ChoiceClassroom from './components-pt/choice-classroom'
import Warmup from './components-pt/warmup'
import Stop from './components-pt/stop'
import Question from './components-pt/question'
import PTStart from './components-pt/pt-start'
import PTResults from './components-pt/pt-results'
import PTResume from './components-pt/resume'
import { isLoggedIn } from './services/isLoggedIn'
import { AnimatePresence } from 'framer-motion'
import TesteDispositivo from './components-pt/teste-dispositivo'
import IntroVideo from './components-pt/intro-video'
import Load from './components/load'
import StudentsResults from './components-pt/student-results'
import Routing from './components/Routing'
import Page404 from './pages/Page404'
import InvalidSlug from './pages/InvalidSlug'
import MicDenied from './pages/mic-denied'
import FooterGlobal from './components/FooterGlobal'
import { readFile } from './api'

const { FRESHDESK_WIDGET_URL } = process.env

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: null
    }
  }  

  componentDidMount() {  
    const loggedIn = isLoggedIn()
    this.setState({ loggedIn })
  }

  render() {  
    const { loggedIn } = this.state
    if (!loggedIn) return <Load />
  
    return (
      <div 
        style={{
          "--img-email-login": `url(${readFile("assets/img/emaillogin.png")})`,
          "--img-lock-login": `url(${readFile("assets/img/locklogin.png")})`,
          "--img-done": `url(${readFile('assets/img/done.png')})`,
          "--img-select-grammar": `url(${readFile('assets/img/selectgrammar.png')})`,
        }}
      >
        {FRESHDESK_WIDGET_URL && (
          <FreshdeskWidget
            url={FRESHDESK_WIDGET_URL}
            type="pop-up"
          >
            <button
              style={{
                border: 0,
                background: "transparent",
                position: "fixed",
                bottom: 20,
                right: 10
              }}
            >
              <FontAwesomeIcon icon={faLifeRing} /> Report a fix
            </button>
          </FreshdeskWidget>
        )}

        <AnimatePresence>
          <Switch> 
            <Routing path="/:slug" exact component={PTHome} />
            <Routing path="/:slug/login" component={PTLogin} />
            <Routing loggedIn={loggedIn} isAuth={true} path="/:slug/choice-classroom" component={ChoiceClassroom} />
            <Routing loggedIn={loggedIn} isAuth={true} path="/:slug/warmup/:order" component={Warmup} />
            <Routing loggedIn={loggedIn} isAuth={true} path="/:slug/start" component={PTStart} />
            <Routing loggedIn={loggedIn} isAuth={true} path="/:slug/stop/:order" component={Stop} />
            <Routing loggedIn={loggedIn} isAuth={true} path="/:slug/question/:model/:question/:position" component={Question} />            
            <Routing loggedIn={loggedIn} isAuth={true} path="/:slug/result" component={PTResults} />
            <Routing loggedIn={loggedIn} isAuth={true} path="/:slug/resume" component={PTResume} />
            <Routing loggedIn={loggedIn} isAuth={true} path="/:slug/teste-dispositivo" component={TesteDispositivo}/>
            <Routing loggedIn={loggedIn} isAuth={true} path="/:slug/mic-denied" component={MicDenied}/>
            <Routing loggedIn={loggedIn} isAuth={true} path="/:slug/student-result/:id" component={StudentsResults} />
            <Routing loggedIn={loggedIn} isAuth={true} path="/:slug/intro-video" component={IntroVideo}/>
            
            <Routing path="/" exact component={InvalidSlug} />
            <Routing path="*" component={Page404} />
          </Switch>
        </AnimatePresence>
        
        <FooterGlobal />
      </div>
    )
  }
}

export default withRouter(App)