import axios from 'axios'
// eslint-disable-next-line no-undef
const { FRONTEND_URL, ADMIN_URL, BACKEND_URL } = process.env

export const extractSlug = () => {
  const { pathname: currentPage } = window.location
  const [ _, slug ] = (currentPage || '').split('/')
  return slug
}

export const isSlug = async () => {
  const slug = extractSlug()
  if (!slug) return false
  const get = await axios.get(`${BACKEND_URL}/distributors/slug/${slug}`)
  return get?.data || false
}

export const baseURL = () => {
  const url = FRONTEND_URL.replace(/%/gi, '')
  return url
}

export const adminURL = () => {
  const url = ADMIN_URL.replace(/%/gi, '')
  return url
}

export const baseSlugURL = () => {
  const url = baseURL()
  const slug = extractSlug()  
  return `${url}/${slug}`
}