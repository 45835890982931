import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Structure from './site-structure'
import { isSlug } from '../services/slug'
import Page404 from '../pages/Page404'
import { readFile } from '../api'

function PTHome ({ match }) {
  const [loading, isLoading] = useState(true)
  const { slug } = match.params

  useEffect(() => { 
    const loadData = async () => {
      document.body.style.background = " linear-gradient(178.87deg, rgba(245, 249, 253, 0.8) 49.67%, rgba(208, 223, 241, 0.8) 96.65%)"
      const loading = await isSlug(slug)
      isLoading(!loading)
    }

    loadData()

    return () => isLoading(true)
  },[])
  
  if (loading) return <Page404 />
  
  return (
    <Structure>
      <form className="fhome">
        <div className="row home">
          <div className="col-12 m-t-80">
            <h1 className="h1-t home tblue">Flex <span className="spc pl-3">Assessment</span></h1>
          </div>
          <div className="col-12 pt-5">
            <img alt="" className="m-t-50 responsive2" src={readFile('assets/img/homeimg.png')} />
          </div>
            <Link to={`/${slug}/login`} className="home btn-p grad-mains m-l-10 ">Entrar</Link>
        </div>
      </form>
    </Structure>
  )
}
export default PTHome