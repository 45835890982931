import React, { Component } from 'react'
import { adminConfig, Api as axios, readFile } from '../api'
import PTAudio from './pt-audio'
import PTMultiplechoice from './pt-multiplechoice'
import { saveTest } from '../services/test'
import Countdown from './countdown'
import QuestionBack from './questionback'
import Button from './send'

export default class PTListeningB extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitting: false,
      isEmpty: true,
      choices: [],
      values: '',
      audio: '',
    }
  }

  componentDidMount() {
    axios.get(`questions/${this.props.id}`)
      .then((response) => {
        const { data } = response
        const audio = {
          blob: adminConfig().root + "/lb/" + data.sentence,
          name: data.sentence,
          file: null
        }

        this.setState({
          ptmodel: data.ptmodel,
          name: data.name,
          order: data.order,
          modelname: data.modelname,
          level: data.level,
          timer: data.timer,
          sentence_type: data.sentence_type,
          sentence: data.sentence,
          choices: data.choices,
          grammar: data.grammar,
          background: data.background,
          audio,
        })
      })
      .catch(function (error) { 
        if (error.response?.status === 404) window.location.href ='/404'
        console.error(error) 
      })
  }

  correctAnswer = (values) => this.setState({ values, isEmpty: false })

  onSave = async () => {
    const { submitting, values } = this.state
    if (submitting) return
    this.setState({ submitting: true }, async () => {
      const redirect = await saveTest(this, 'Listening', values)
      if (redirect) window.location.href = redirect
    })
  }

  onSubmit = (e) => e.preventDefault()

  render() {
    const { values, timer, audio, choices, background, isEmpty } = this.state
    const backgroundColor = background && background !== '' && adminConfig().root + "/back/lb/" + background

    return (
      <div>
        <Countdown
          questionId={this.props.id}
          timer={timer}
          color="toptimer white t-blue"
          colortext="bottimer txt-blue"
          next={this.onSave}
        />

        <form className="m-t-20" onSubmit={this.onSubmit}>              
          <PTMultiplechoice
            values={values}
            choices={choices}
            correctAnswer={this.correctAnswer}
          />

          <PTAudio 
            playImage={readFile('assets/img/play_arrow.png')} 
            replayImage={readFile('assets/img/replay.png')}
            audio={audio}
          />

          <QuestionBack src={backgroundColor}/>  

          <Button 
            className="btn-big gradient-blue"
            onClick={this.onSave} 
            isEmpty={isEmpty} 
          />
        </form>
      </div>      
    )
  }
}
