import React, { useEffect, useState, useMemo } from 'react'
import { Api as axios } from '../api'
import { isNil } from 'lodash'
import { getUserSession } from '../services/isLoggedIn'

export default function Countdown ({ 
  questionId, 
  timer: base, 
  next, 
  color, 
  colortext 
}) {
  const [currentCount, setCurrentCount] = useState(null)
  const [timer, setTimer] = useState(base)
  const [colortextState, setColorTextState] = useState(colortext)
  const user = useMemo(() => getUserSession('userPT'), [])
  const baseTime = useMemo(() => Math.ceil(parseInt(base, 10) * 0.1), [base])

  const clearTime = (intervalId) => {
    if (intervalId) clearTimeout(intervalId)
    if (next) next()
  }

  const clock = () => setCurrentCount(currentCount - 1)

  useEffect(() => {
    const counter = parseInt(timer, 10)
    if (Number.isInteger(counter)) {
      setCurrentCount(counter)
    }
  }, [timer])
  
  useEffect(() => {
    if (isNil(currentCount)) return
    let intervalId

    const color = (currentCount <= baseTime) ? 'bottimer txt-error' : colortext
    setColorTextState(color)

    if (currentCount > 0) {
      intervalId = setTimeout(clock, 1000)
      return
    }
    clearTime(intervalId)
  }, [currentCount])

  useEffect(() => {
    const load = async () => {
      if (!questionId) return
      const response = await axios.get(`test/${user?.testID || 'warmup'}/${questionId}/timer`)
      if (!response) return
      const { timer } = response?.data || {}
      setTimer(timer)
    }

    load()
  }, [questionId])

  if (isNil(timer)) return <div />

  return (
    <div className="w-100px timer">
      <div className={color}>Timer</div>
      <div className={colortextState}>
        <section className="p-2 font-20">{currentCount}</section>
      </div>
    </div>
  )
}
