import { apiURL, Api as axios } from '../api'
import { getUserSession, setUserSession } from '../services/isLoggedIn'
import { warmup } from './warmup'
import { uploadAudio } from './upload'
import { baseSlugURL } from './slug'

const { SPEAKING_API } = process.env

const BASE_SLUG_URL = baseSlugURL()

const API_URL = ''
const baseUrl = `${API_URL}test` 

const TEST_WEIGHT = { 
  positive: 0,
  negative: 0,
  positive_count: 0,
  negative_count: 0 
}

export const createTest = async (model, isRedirect = true) => { 
  const user = getUserSession('userPT')
  const isFirst = model === 'Speaking'
  const route = isFirst ? 'create' : `update/${user?.testID}`
  const testURL = `${baseUrl}/${route}/questions/model/${model}`
  const response = await axios.get(testURL)
  if (!response) return
  const { _id: testID, questions } = response.data
  if (!testID || !questions) return

  if(isFirst) {
    user.testID = testID
    user.test_weight = TEST_WEIGHT
    setUserSession('userPT', JSON.stringify(user))
  }
  
  let redirect = null
  if (isRedirect) {
    const [question] = questions || []
    if (question) redirect = `${BASE_SLUG_URL}/question/${question.url}/${question.id}`
  }
  return { testID: user.testID, redirect }
}

export const saveTest = async ({ props, state }, model, selectedAnswer) => {
  const { pathname: origin } = window.location
  //flow warmup
  if(origin.search('warmup') > -1 || origin.search('teste-dispositivo') > -1){    
    return warmup(origin)       
  }
  const user = getUserSession('userPT')  
  if (!user.testID || user.testID === '') {
    const { testID, redirect } = await createTest('Speaking', false)
    user.testID = testID
    if (redirect) return redirect
  }
  
  if (!user.test_weight) user.test_weight = TEST_WEIGHT
  const { test_weight = TEST_WEIGHT, testID } = user
  
  const current_position = parseInt(props.position, 10)
  const speechRecognition = model === 'Speaking' && state.blob !== null 
  ? await speechRecognitionApi(state, testID, current_position) 
  : { exchange_data: { status: 'no audio' }}
  
  const data = { 
    param: state,
    current_position,
    selectedAnswer,
    speechRecognition,
    test_weight
  }
  const response = await axios
    .post(`${baseUrl}/${testID}/model/${model}/question/update`, data)    
  if (!response) return
  const { data: question } = response
  user.test_weight = question.test_weight_data;
  
  setUserSession('userPT', JSON.stringify(user))
  
  const next_question_key = await getNextQuestion(question, testID, user)
  if(next_question_key === 'INITIAL') return saveTestAbility(testID, user)
  
  const [next_question_response] = next_question_key || []
  if (next_question_response.url && next_question_response.id) {
    return `${BASE_SLUG_URL}/question/${next_question_response.url}/${next_question_response.id}`
  }
  return
}

export const speakingWarmupFeedback = async({ props, state }) => {
  const { params } = props.match
  const user = getUserSession('userPT')
  const current_position = parseInt(params.order, 10)

  let feedback = ''
  const { testID } = user

  const speechRecognition = state.blob !== null 
  ? await speechRecognitionApi(state, testID, current_position) 
  : { exchange_data: { status: 'error' }}
  const { status = 'error', text_score = 0 } = speechRecognition?.exchange_data
  if (status === 'error' || !speechRecognition) return 'Ops, algo deu errado. Grave novamente.' 
  const feedbackValue = text_score?.quality_score || 0;

  const textsFeedback = {
    "0-40": { text: "Ops, algo deu errado. Grave novamente.", color: "#FF5630" },
    "41-70": { text: "Hum, legal mas ainda pode melhorar. Tente novamente.", color: "#FFC400" },
    "71-85": { text: "Isso! Você está no caminho.", color: "#36B37E" },
    "86-100": { text: "Excelente!", color: "#36B37E" },
  }

  Object.keys(textsFeedback).forEach((interval) => {
    const [min, max] = interval.split("-");
    if (feedbackValue >= min && feedbackValue <= max) {
      feedback = textsFeedback[interval];
    }
  });

  return {
    feedback,
    allowNextQuestion: feedbackValue > 70,
  }
}

const speechRecognitionApi = async(state, testID, filename) => {  
  if (!state.blob) return null
  //upload AWS
  const file = new File([state.blob], filename, { type: 'audio/mp3' })
  const url = apiURL()
  const uploadFile = await uploadAudio(file, filename, `audio/${testID}`)
  if (uploadFile?.status !== 204) return null

  const postData = {
    file: uploadFile?.location,
    answer: state.choices[0].word
  }
  
  if (!postData.file) return null  
  //send to the API
  const object = await axios.post(`${url}speechrecognition/${SPEAKING_API}`, postData)
  return (object.status === 200) ? object.data : null
}

// *** Rule description *** //
//If you have -20 negative points, the test ends, else , you continue.
//If you make a mistake If you have >= 85 positive points, you pass to the next level.
const getNextQuestion = async (question, testID, user) => {
  const { test_weight } = user
  const response = await axios.post(`${baseUrl}/nextStatus/${testID}`, { question, test_weight })
  return response.data
}

const saveTestAbility = async (testID, user) => {    
  const response = await axios.post(`${API_URL}results/create/test/${testID}`, user.test_weight)
  if (!response) return

  updateUserSession(user)
  const { data } = response  
  return nextAbility(data.testType)
}

export const resume = async () => {  
  const user = getUserSession('userPT')
  const response = await axios.get(`${baseUrl}/getTest/${user.testID}`)  
  const [test] = response.data

  //current question   
  let current_question = test?.questions[test?.questions?.length - 1]
  if (!current_question) {
    return `${BASE_SLUG_URL}/start`
  }

  //teste iniciado
  if(current_question?.status === 'done') {
    test.questions.forEach(question => {
      const { question_weight: question_weight_original, iscorrect = false } = question || {}
      const { correct = 0, wrong = 0 } = question_weight_original || {}

      const { test_weight } = user || {}
      const { positive = 0, negative = 0, positive_count = 0, negative_count = 0 } = test_weight || {}

      user.test_weight.positive       = iscorrect ? positive + correct : positive
      user.test_weight.negative       = iscorrect ? negative : negative + wrong
      user.test_weight.positive_count = iscorrect ? positive_count + 1 : positive_count 
      user.test_weight.negative_count = iscorrect ? negative_count : negative_count + 1
    })

    const next_question = await getNextQuestion(current_question, user.testID, user)     
    setUserSession('userPT', JSON.stringify(user))

    if(next_question === 'INITIAL') {
      updateUserSession(user)
      return nextAbility(current_question.model)
    }    
    current_question = next_question[0]
  }
  
  return `${BASE_SLUG_URL}/question/${current_question.url}/${current_question.id}`
}

const nextAbility = async (model) => {  
  const MODELS = {
    Speaking: '/stop/1',
    Listening: '/stop/2',
    Grammar: '/stop/3',
    Reading: '/stop/4',
    Writing: '/result',
  }

  const route = MODELS[model]
  if (route === '/result') await calculateResults()
  return `${BASE_SLUG_URL}${route}`
}

const calculateResults = async () => {
  const user = getUserSession('userPT')
  await axios.post(`${API_URL}report/create/test/${user.testID}`, { classroom: user?.classroom })
  await axios.delete(`${baseUrl}/${user.testID}`)
}

const updateUserSession = (user) => {
  if (!user) return
  user.test_weight = TEST_WEIGHT
  setUserSession('userPT', JSON.stringify(user))
}
