import React, { useState, useEffect } from 'react'
import Structure from './site-structure'
import { nextModel, stopLayout } from '../services/stop'
import Header from './header'

export default function Stop ({ match }) {
  const [layout, setLayout] = useState({
    currentModel: '',
    nextModel: '',
    backgroundImage: ''
  })
  
  useEffect(() => {
    const layout = stopLayout(match.params?.order || 0)
    setLayout(layout)   
    document.body.style.backgroundImage = layout.backgroundImage   
  }, [])

  const onClick = async (e) => { 
    e.preventDefault()
    const { redirect } = await nextModel(match.params.order)
    if (redirect) window.location.href = redirect
  }

  return (
    <Structure>
      <Header/>
      <div className="card m-t-50 ">
          <div className={layout.border}>
              <div className={layout.titulo}>Parabéns!</div>
                  <div className="m-t-20">
                      <div className="col-sm-6 d-flex m-auto justify-content-center">
                          <div>
                              <img alt="" src={layout.medal} />
                          </div>
                          <div className="text-left">
                              <ul>
                                  <li className="font-24 f-r t-dark"> Etapa</li>
                                  <li className="tstop t-dark">{layout.currentModel}</li>
                                  <li className="font-35 f-r t-dark"> Concluída!</li>
                              </ul>
                          </div>
                      </div>
                  </div>
              <div className="m-b-20">
                  <div className="col-sm-10 m-auto">
                      <div className="m-t-30 start">
                        A etapa {layout.nextModel} começará em breve 
                      </div>
                  </div>
                  <div className="col-md-8 m-auto">
                    <div className="linha3 m-auto">
                      <div className={layout.linha}></div>
                    </div>
                    <button className={layout.btn} onClick={onClick}>next</button>
                  </div>
              </div>
          </div>
      </div>
    </Structure>
  )
}