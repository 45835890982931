import React, { Component } from 'react'
import { adminConfig, Api as axios } from '../api'
import Button from './send'
import PTQuestion from './pt-question'
import MicRecorder from 'mic-recorder-to-mp3'
import { saveTest, speakingWarmupFeedback } from '../services/test'
import Countdown from './countdown'
import Recording from '../components/Recording'
import QuestionBack from './questionback'

const Mp3Recorder = new MicRecorder({ bitRate: 128 })

export default class PTSpeakingB extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitting: false,
      isEmpty: true,
      values: '',
      stop: 'none',
      play: 'block',
      player: 'none',
      blob: null,
      feedbackWarmup: {},
      loading: false,
    }
  }

  componentDidMount() {
    axios.get("questions/" + this.props.id)
      .then((response) => {
        const audio = {
          blob: adminConfig().root + "/sb/" + response.data.sentence,
          name: response.data.sentence,
          file: null
        }

        this.setState({
          ptmodel: response.data.ptmodel,
          name: response.data.name,
          order: response.data.order,
          modelname: response.data.modelname,
          level: response.data.level,
          timer: response.data.timer,
          sentence_type: response.data.sentence_type,
          sentence: response.data.sentence,
          choices: [{ word: response.data.sentence }],
          grammar: response.data.grammar,
          values: response.data.sentence,
          background: response.data.background,
          audio
        })
      })
      .catch(function (error) { 
        if (error.response?.status === 404) window.location.href ='/404'
        console.error(error) 
      })
  }

  start = () => {
    const { isBlocked } = this.state
    if (isBlocked) {      
      alert('Permission to use microphone denied.')
      return null
    } 
    Mp3Recorder
      .start()
      .then(() => {
        this.setState({ isRecording: true }, () => {
          const fadee = document.getElementById('fadee')
          if(fadee) fadee.className = "fade opacity modal-backdrop show"
          this.controlvisibility("none", "block")
        })
      })
      .catch((e) => console.error(e))
  }

  stop = () => {
    const { isWarmup } = this.props
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {          
        this.controlvisibility("block", "none")
        document.getElementById("fadee").className=''
        this.setState({ blob, loading: true }, async () => {
          const file = new File(buffer, 'audio.mp3', {
            type: blob.type,
            lastModified: Date.now()
          })
          const blobURL = URL.createObjectURL(file)
          this.setState({ blobURL, isRecording: false, isEmpty: false })
          if (isWarmup) {
            const feedbackWarmup = await speakingWarmupFeedback(this)
            this.setState({ feedbackWarmup, loading: false })
          }
          else {
            this.onSave()
          }
        })
      })
      .catch(console.error)
  }

  controlvisibility = (play, stop) => {
    this.setState({ play, stop, player: play })  
    document.getElementById("bt_next").style.display = play
  }
  
  onSave = async () => {
    const { submitting } = this.state
    if (submitting) return
    this.setState({ submitting: true }, async () => {
      const redirect = await saveTest(this, 'Speaking')
      if (redirect) window.location.href = redirect
    })
  }

  onSubmit = (e) => e.preventDefault()

  render() {
    const { 
      timer, 
      sentence, 
      play, 
      stop, 
      player, 
      blobURL, 
      background, 
      isRecording,
      feedbackWarmup, 
      isEmpty,
      loading,
    } = this.state
    const { isWarmup } = this.props
    const backgroundColor = background && background !== '' && adminConfig().root + "/back/sb/" + background
    const { feedback, allowNextQuestion = true } = feedbackWarmup
    const checkingFeedback = isWarmup ? !feedback : false

    return (
      <form className="text-center" onSubmit={this.onSubmit}> 
        <Countdown
          questionId={this.props.id}
          timer={timer} 
          color="toptimer white t-green" 
          colortext="bottimer txt-green" 
          next={(!checkingFeedback && allowNextQuestion) && this.onSave}
        />

        <br/>  

        <PTQuestion sentence={sentence} />          

        <div className="row"> 
          <div  className="col-md-11 m-auto">
            <div className=" d-flex mt-4 mb-4">
              <div onClick={this.start} className={ " mic-b " + play }> 
                <svg width="5em" height="7em" viewBox="0 0 16 16" className="bi bi-mic-fill mic" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
                  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                </svg> 
              </div>
              <div onClick={this.stop} className={ " " + stop }>
                <Recording isRecording={isRecording} onFinished={this.stop} />
              </div>                 
            </div> 
          </div>
        </div>

        <div className={`row justify-content-center ${player}`}>
          <audio id="recording" src={blobURL} controls={false} />
        </div>            

        <div style={{ color: feedback?.color }}>{feedback?.text}</div>

        <Button 
          className="btn-big btn-next gradient-green mt3"
          onClick={this.onSave} 
          isEmpty={isEmpty}
          disabled={loading || !allowNextQuestion}
          loading={loading}
        />

        <QuestionBack src={backgroundColor}/>  
      </form>
    )
  }
}
