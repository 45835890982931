import React, { Component } from 'react'
import { adminConfig, Api as axios, readFile } from '../api'
import PTAudio from './pt-audio'
import PTMultiplechoicerecord from './pt-multiplechoice-record'
import Button from './send'
import MicRecorder from 'mic-recorder-to-mp3'
import { saveTest, speakingWarmupFeedback } from '../services/test'
import Countdown from './countdown'
import QuestionBack from './questionback'

const Mp3Recorder = new MicRecorder({ bitRate: 128 })

export default class PTSpeakingA extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      isEmpty: true,
      choices: [],
      values: '',
      audio: '',
      stop: 'none',
      play: 'block',
      player: 'none',
      blob: null,
      feedbackWarmup: {},
      loading: false,
    }
  }

  componentDidMount() {
    axios
      .get(`questions/${this.props.id}`)
      .then((response) => {
        const { sentence } = response.data
        const audio = {
          blob: adminConfig().root + "/sa/"+ sentence,
          name: sentence,
          file: null
        }

        this.setState({ ...response.data, audio })
    })
    .catch(function (error) { 
      if (error.response?.status === 404) window.location.href ='/404'
      console.error(error) 
    })
  }

  start = (key) => { 
    const { isBlocked, choices } = this.state

    if (isBlocked) {
      alert('Permission to use microphone denied.')
      return null
    }
    const [choice] = choices
    Mp3Recorder
      .start()
      .then(() => {
        this.setState({ isRecording: true }, () => {
          this.setState({ values: choice?.optionlist[key] })
          this.controlvisibility("block", "fade modal-backdrop opacity show")
          this.border(key)                     
        })
      })
      .catch((e) => console.error(e))
  }

  stop = () => {
    const { isWarmup } = this.props
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {        
        this.setState({ blob, player: 'block', loading: true }, async () => {
          const file = new File(buffer, 'audio.mp3', {
            type: blob.type,
            lastModified: Date.now()
          })
          const blobURL = URL.createObjectURL(file)
          this.setState({ blobURL, isRecording: false, isEmpty: false })
          this.controlvisibility('none', '','options opg d-flex justify-content-center m-t-5')
          if (isWarmup) {
            const feedbackWarmup = await speakingWarmupFeedback(this)
            this.setState({ feedbackWarmup, loading: false })
          }
          else {
            this.onSave()
          }
        })
      })
      .catch((e) => console.error(e))
  }

  controlvisibility = (state2, className) => {     
    const stop = document.getElementById('stop')
    const fadee = document.getElementById('fadee')
    if (stop) stop.style.display = state2
    if(fadee) fadee.className = className
  }

  onSave = async () => {
    const { submitting } = this.state
    if (submitting) return
    this.setState({ submitting: true }, async () => {
      const redirect = await saveTest(this, 'Speaking')
      if (redirect) window.location.href = redirect
    })
  }

  onSubmit = (e) => e.preventDefault()

  border = (key) => {
    for(let i = 0; i < 4; i++) {
      document.getElementById("play_" + i).className = "options d-flex justify-content-center m-t-5"
    }
    document.getElementById("play_" + key).className = "options opg d-flex justify-content-center m-t-5"            
  }

  render() {
    const { 
      isRecording, 
      values, 
      choices, 
      timer, 
      player, 
      blobURL, 
      audio, 
      background,
      feedbackWarmup,
      isEmpty,
      loading,
    } = this.state
    const { isWarmup } = this.props
    const backgroundColor = background && background !== '' && adminConfig().root + "/back/sa/" + background
    const { feedback, allowNextQuestion = true } = feedbackWarmup
    const checkingFeedback = isWarmup ? !feedback : false

    return (
      <form onSubmit={this.onSubmit}>
        <Countdown
          questionId={this.props.id}
          timer={timer}
          color="toptimer white t-green"
          colortext="bottimer txt-green"
          next={(!checkingFeedback && allowNextQuestion) && this.onSave}
        />

        <div className="row mt-2">
          <div className="col-md-9 m-auto">
            <PTMultiplechoicerecord
              values={values}
              choices={choices}
              correctAnswer={this.correctAnswer}
              isRecording={isRecording}
              start={this.start}
              stop={this.stop} 
            />
          </div>
        </div>
        <div className={`row justify-content-center ${player}`}>
          <audio id="recording" src={blobURL} controls={false} />
        </div>

        <PTAudio 
          playImage={readFile('assets/img/play_arrow.png')}
          replayImage={readFile('assets/img/replaygreen.png')}
          audio={audio}       
        />

        <div style={{ color: feedback?.color }}>{feedback?.text}</div>

        <Button 
          className="btn-big btn-next gradient-green" 
          onClick={this.onSave} 
          isEmpty={isEmpty}
          disabled={loading || !allowNextQuestion}
          loading={loading}
        />
        
        <QuestionBack src={backgroundColor} />  
      </form>
    )
  }
}
