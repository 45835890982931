import React from 'react'

function PTImages ({ values , onChange, onChangeValues, files }) {
  return (
    <div className="w-500 m-auto m-t-20">
      <div 
        onChange={onChange} 
        className="row justify-content-center m-b-20"
      >
        {(files || []).map(file => (
          <div 
            value={file.name} 
            onClick={() => onChangeValues(file.name)} 
            key={"rd_" + file.name} 
            className={`wm-200  m-1 b-img ${values === file.name ? 'btnblue' : ''}`}
          >
            <img 
              key={file.name} 
              className="imgpreview card-img-top img-fluid" 
              src={file.blob} 
              alt="..." 
            />
          </div>
        ))}
      </div>
    </div>
  )
}
export default PTImages
