import React, { Component } from 'react'
import StudentResultBase from '../components-pt/student-result-base'
import { getUserSession } from '../services/isLoggedIn'
import { Api as axios, readFile } from '../api'

export default class PTResults extends Component {
  constructor(props) {
    super(props)
      this.state = {
        test:[]
      }          
  }  

  componentDidMount() {
    this.loadData()                
  }

  loadData = async () => {
    document.body.style.backgroundImage = `url('${readFile('assets/img/fundoresults.png')}')`
    document.body.style.backgroundColor = "#dcf5ff94"

    const testId = getUserSession("userPT").testID
    const report = await axios.get("report/by-test/" + testId)   
    this.setState({ report: report.data }) 
  } 
   
  render(){
    return <StudentResultBase test={this.state.report} />
  }
}
