import React, { Component } from 'react'
import Structure from './site-structure'
import PTListeningA from './pt-listening-a'
import PTListeningB from './pt-listening-b'
import PTReadingA from './pt-reading-a'
import PTReadingB from './pt-reading-b'
import PTWritingA from './pt-writing-a'
import PTWritingB from './pt-writing-b'
import PTSpeakingA from './pt-speaking-a'
import PTSpeakingB from './pt-speaking-b'
import PTGrammarA from './pt-grammar-a'
import PTGrammarB from './pt-grammar-b'
import { warmupAction, warmupLayout } from '../services/warmup'
import Whois from './whois'
import { baseSlugURL } from '../services/slug'

const BASE_SLUG_URL = baseSlugURL()

const TYPES = {
  'whois':  Whois,
  'speaking-a': PTSpeakingA,
  'speaking-b': PTSpeakingB,
  'grammar-a': PTGrammarA,
  'grammar-b': PTGrammarB,
  'reading-a': PTReadingA,
  'reading-b': PTReadingB,
  'listening-a': PTListeningA,
  'listening-b': PTListeningB,
  'writing-a': PTWritingA,
  'writing-b': PTWritingB,
}
export default class Warmup extends Component {
  constructor(props) {
    super(props) 
    this.state = {
      items: null,
      order: '',
      layout: {
        model: '',
        icon: '',
        subtitle: '',
        color: '',
        cordar: '',
        cordal: '',
        bolal: '',
        bolar: '',
      }
    }
  } 

  componentDidMount() {
    this.loadData()
  }
  
  loadData = async () => {
    const { match } = this.props
    const { order = 0 } = match.params
    const layout = warmupLayout(order)    
    const items = await warmupAction('GET')
    this.setState({ layout, order, items })
  }

  render() { 
    const { layout, order, items } = this.state
    const { match } = this.props
    if (!layout || !order || !items) return <div />
    
    const item = items[order]
    const Component = TYPES[item?.type]
    if (!item || !Component) {
      window.location.href = `${BASE_SLUG_URL}/start`

      return
    }

    return ( 
      <Structure>
        <div className="text-center m-t-20 aq">
          <div className="text1">Preparação</div>
          <div className={layout.color}>{layout.model}</div>
          <div className={layout.cordal}>
            <div className={layout.bolal}></div>
          </div>
          <div className={layout.cordar}>
            <div className={layout.bolar}></div>
          </div>
          <div className="row"> 
            <div className="card col-sm-12 sha m-t-20">
              <div className="card-body">
                <img className="w-70" alt="" src={layout.icon}/>
                <div className="st3">{layout.subtitle}</div>
                <div>
                  <Component match={match} id={item?._id} isWarmup />
                </div>                 
              </div>
            </div>
          </div>
        </div>
      </Structure>
    )
  }
}
