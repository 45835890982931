import React from 'react'

function AnswerBox ({ chuncks, choices, onChange }) {
  return (
    <div className="row text-center m-t-40">
      <div className="col-sm">
        <div className="form-group">
          {chuncks.map((text, index) => (
            <div className="p-1 d-inline-block m-t-10" key={index}>
              {text.position.length !== undefined ? (
                <div className="st2">{text.text}</div>
              ) : (                     
                <select 
                  name={text.position} 
                  className="m-t-8" 
                  id="apparence-select" 
                  onChange={onChange}
                >
                  <option key={"opt_"} value="-" className="f-18">-</option>
                  {choices[text.position].optionlist.map(
                    (text, index) => (
                      <option key={"opt_" + index} value={text} className="stm1">
                        {text}
                      </option>
                    ) 
                  )}
                </select>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default AnswerBox
