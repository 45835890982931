import React, { useEffect } from "react"
import Structure from "./site-structure"
import Header from './header'
import { Link } from "react-router-dom" 
import { readFile } from "../api"

function TesteIntro ({ match }) {
  useEffect(() => {
    document.body.style.background= " linear-gradient(178.87deg, rgba(245, 249, 253, 0.8) 12.91%, rgba(208, 223, 241, 0.8) 32.31%, rgba(190, 213, 240, 0.8) 98.18%)"
  },[])

  const { slug } = match.params

  return ( 
    <Structure>
      <Header/>
      <div className="row  margin">
        <div className="col-md d-flex justify-content-center">
          <div className="h1-t tblue v">Flex <span className="spc">Assessment</span></div>
        </div>
      </div>
      <div className="row  margin">
        <div className="col-md d-flex justify-content-center">
          <video id="video-intro" controls width="70%" height="100%" src={readFile('assets/img/newvideo.mp4')} type="video/mp4"/>
        </div>
      </div>
      <div className="row margin">
        <div className="col-md d-flex justify-content-center mt3" >
          <Link className=" btn-big grad-mains" to={`/${slug}/warmup/0`}>PROXIMA ETAPA</Link>
        </div>
      </div>
    </Structure>
    )
  }
export default TesteIntro