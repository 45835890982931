import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Structure from './site-structure'
import { login as fetchLogin } from '../services/isLoggedIn'
import { loginToken as fetchTokenJWT } from '../services/auth'
import Load from '../components/load'
import '../css/estilo.css'
import { readFile } from '../api'
export default class PTLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      login: '',
      pwd: '',
      message: ''
    }
  }

  componentDidMount() {  
    document.body.style.background = "linear-gradient(178.87deg,#F5F9FD 24.14%,#D0DFF1 71.12%,#BED5F0 98.18%)"
    this.setState({ loading: false }, () => this.checkJWT())
  }

  checkJWT = async () => {
    const jwtToken = new URLSearchParams(window.location.search).get('accessToken')
    if(!jwtToken) return
    this.setState({ loading: true }, async () => {
      const message = await fetchTokenJWT(jwtToken)
      setTimeout(() => { this.setState({ message, loading: false }) }, 2)
    })
  }

  onChangeHandler = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: true }, async () => {
      try {
        const { login, pwd } = this.state
        if (!login || !pwd) {
          alert('Check yours credentials!')
          return null
        }
        const message = await fetchLogin(login, pwd)
        this.setState({ message })
      } catch (error) {
        console.error(error)
        const message = 'Authentication failed'  
        this.setState({ message })
      } finally {
        this.setState({ loading: false })
      }      
    })
  }

  render() {
    const { message, loading, login, pwd } = this.state
    if (loading) return <Load display="block" />

    return (
      <Structure>
        <div className="placementlogin">
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-md-7 img-log m-auto align-self-end">
                <img alt="" className="" src={readFile('assets/img/loginimg.png')} />
              </div>
              <div className="col-xl-5 m-auto">
                <div className="card m-t-15 loginblock">
                  <div className="card-body">
                    <div className="text-center m-t-30">
                      <h3>
                        <img src={readFile('assets/img/loginlivros.png')} alt="logo" />
                      </h3>
                    </div>
                    <div className="login "> 
                      <div className="hb-l">Bem vindo ao Flex Assessment!</div>
                      <div className="form-group">
                        <input id="user" type="text" autoCapitalize="off" name="login" placeholder=" nome@email.com" required className="form-control" value={login} onChange={this.onChangeHandler}/>
                      </div>
                      <div className="form-group">
                        <input id="psw" type="password" name="pwd" placeholder="*******" required className="form-control" value={pwd} onChange={this.onChangeHandler}/>
                      </div>
                      <div className={(message || '').length > 0 ? "form-group alertlogin alert-danger" : ""} role="alert">
                        <span>{message}</span>
                      </div>
                      <div className="text-center m-t-40">
                        <input type="submit" value="Log In" className="btn-p grad-mains"/>            
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Structure>
    )
  }
}