import ReactS3 from 'aws-s3'
import { adminConfig } from '../api'

export const uploadBackground = (props, dir) => {
  const { file } = props.state
  if (!file.file || file.file === '') return
  if (!dir) return
  const S3Client = new ReactS3(adminConfig(dir).config)
  S3Client
    .uploadFile(props.state.file.file)
    .then(() => { 'uploaded!' })
    .catch(err => { console.error(err) })  
}

export const uploadAudio = async (file, name, dir) => {
  if (!file || !name || !dir) return    
  const S3Client = new ReactS3(adminConfig(dir).config)
  return S3Client.uploadFile(file, name)
}
