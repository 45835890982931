import React from 'react'
import { clearSession, getCurrentUser } from '../services/isLoggedIn'
import { extractSlug } from '../services/slug'
import { readFile } from '../api'

function urlencode(str) {
  str = (str + '').toString().trim().toUpperCase()
  return encodeURIComponent(str)
    .replace('!', '%21')
    .replace('\'', '%27')
    .replace('(', '%28')
    .replace(')', '%29')
    .replace('*', '%2A')
    .replace('%20', '-')
}

function Header() {
  const slug = extractSlug()
  const { school } = getCurrentUser()

  const url = readFile('assets/img/logos');
  const logo = `${url}/logo-${slug || 'conexia'}.png`

  const onClearSession = async (data) => {
    const redirect = await clearSession(data)
    if (!redirect) return
    setTimeout(() => { window.location.href = redirect }, 2)
  }

  return(
    <div className="row margin  ma-b">
        <div className="col-2 header">
          <img className="logo" src={logo} alt="" />
        </div>

        {school?.name !== '' && urlencode(school.name) !== slug.toUpperCase() && (
          <div className="col-2 header">
            <img className="logo hf" src={`${url}/logo-${urlencode(school.name)}.png`} alt="" />
          </div>
        )}
        <div className="col-8 d-flex justify-content-end">
          <a onClick={() => onClearSession("userPT")} className="btn-sair sair">Sair</a>
        </div>
    </div>
  )
}
export default Header