import { readFile } from '../api'
import { createTest } from '../services/test'

export const stopLayout = (order) => {
  switch(order) {
    case '1': //Speaking
      return {
        linha:'linha4 p',
        btn:' btn-big gradient-purple m-t-50',
        titulo:'tstop  m-t-20 m-b-30 cstop-p',
        medal:readFile('assets/img/m-purple.png'),
        border:'bord-purple card-body text-center',
        currentModel: 'Speaking',
        nextModel: 'Listening',
        backgroundImage: `url('${readFile('assets/img/fundoroxo.png')}')`
      }
      break
    case '2': //Listening
       return {
         linha:'linha4 b',
         btn:' btn-big gradient-blue m-t-50 ',
         titulo:'tstop m-t-20 m-b-30 cstop-b',
         medal: readFile('assets/img/m-blue.png'),
         border:'bord-blue card-body text-center',
         currentModel: 'Listening',
         nextModel: 'Grammar',
         backgroundImage: `url('${readFile('assets/img/fundoazul.png')}')`
       }
    case '3': //Grammar    
      return {
        linha:'linha4 o-g',
        btn:' btn-big gradient-orange m-t-50',
        titulo:'tstop m-t-20 m-b-30 cstop-o',
        medal: readFile('assets/img/m-orange.png'),
        border:'bord-orange card-body text-center',
        currentModel: 'Grammar',
        nextModel: 'Reading',
        backgroundImage: `url('${readFile('assets/img/backpink.png')}')`
      }
    case '4': //Reading
      return {
        linha:'linha4 o-r',
        btn:' btn-big gradient-orange m-t-50',
        titulo:'tstop  m-t-20 m-b-30 cstop-o',
        medal: readFile('assets/img/m-orange.png'),
        border:'bord-orange card-body text-center',
        currentModel: 'Reading',
        nextModel: 'Writing',
        backgroundImage: `url('${readFile('assets/img/backpink.png')}')`
      }
    default:
      return
  }
}

export const nextModel = async (model) => { 
  if (!model) return
  const MODELS = {
    '1': 'Listening',
    '2': 'Grammar',
    '3': 'Reading',
    '4': 'Writing',
  }
  if (MODELS[model]) return createTest(MODELS[model])
  return {}
}
