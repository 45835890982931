import { extractSlug } from '../services/slug'

const slug = extractSlug()

const { 
  PUBLIC_URL
  // eslint-disable-next-line no-undef
} = process.env;

function getLocalStreamV2(response, error) {
  return navigator.mediaDevices.getUserMedia({ video: false, audio: true })
      .then( stream => {
        if (response) response(stream)
        return true
      }).catch( err => {
        console.error(`u got an error:${err}`)
        if (error) error(err)        
        return false
      });
}

function getLocalStream() {
  getLocalStreamV2((stream) => {
    console.info(stream)
  }, 
  (err) => {
    const urlParams = new URLSearchParams(window.location.search)
    const hash = urlParams.get('h')
    if (!hash) {
      window.location.href = `${PUBLIC_URL}/${slug}/mic-denied?h=${Math.random()}&e=${err}`
    }
  })
}

export {
  getLocalStream,
  getLocalStreamV2
}