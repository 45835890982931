import React from 'react'

function Multiplechoice({ values, choices, correctAnswer }){
  return (
    <div className="row m-t-10">
      {choices.map((choice, key) => (
        <div key={key} className="col-md-9 m-auto">
          <div className="m-b-20 ">
            <div className="form-group ">
              {choice.optionlist.map((text) => (
                <div
                  onClick={() => correctAnswer(text)} 
                  key={"opt_" + text} 
                  className={`options d-flex justify-content-center m-t-5 ${values === text ? 'btnblue' : ''}`}
                >
                  <div className="stm1">{text}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>    
  )
}
export default Multiplechoice
