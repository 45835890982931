import { readFile } from "../api"

export const questionLayout = (model) => { 
  switch(model) {
    case "listening-a":    
      return {
        model: model,
        color:"tl-1 cl-b", 
        name:"Listening",
        bolal:"bola bdb-b  b-l",
        bolar:"bola bdb-b  b-r",
        cordar:"corda bd-b cr-r",
        cordal:"corda bd-b  cr-l",
        icon: readFile('assets/img/redfone.png'),
        subtitle: "Escute o áudio e selecione a alternativa que melhor condiz ao enunciado. Você pode ouvir novamente quantas vezes precisar, enquanto houver tempo.",
        backgroundImage: `url('${readFile('assets/img/fundoazul.png')}')` ,
        background: "#c9e8f9"
      }        
    case "listening-b":   
      return {
        model: model,
        color:"tl-1 cl-b", 
        name:"Listening",
        bolal:"bola bdb-b b-l",
        bolar:"bola bdb-b b-r",
        cordar:"corda bd-b cr-r",
        cordal:"corda bd-b cr-l",
        icon: readFile('assets/img/redfone.png'),
        subtitle: "Escute o áudio e selecione a imagem que melhor condiz ao enunciado. Você pode ouvir novamente quantas vezes precisar, enquanto houver tempo.",
        backgroundImage: `url('${readFile('assets/img/fundoazul.png')}')`,
        background:"#c9e8f9"
      }  
    case "reading-a":   
      return { 
        model: model,
        color:"tl-1 cl-o", 
        name:"Reading",
        bolal:"bola bdb-o b-l",
        bolar:"bola bdb-o b-r",
        cordar:"corda bd-o cr-r",
        cordal:"corda bd-o cr-l",
        icon: readFile('assets/img/nota.png'),
        subtitle: "Leia o enunciado e selecione a imagem correspondente.",
        backgroundImage: `url('${readFile('assets/img/backpink.png')}')`,
        background: "rgb(240 202 186 / 69%)"
      }  
    case "reading-b":   
      return {
        model: model,
        color:"tl-1 cl-o", 
        name:"Reading",
        bolal:"bola bdb-o b-l",
        bolar:"bola bdb-o b-r",
        cordar:"corda bd-o cr-r",
        cordal:"corda bd-o cr-l",
        icon: readFile('assets/img/nota.png'),
        subtitle: "Leia o enunciado e selecione a alternativa correspondente.",
        backgroundImage: `url('${readFile('assets/img/backpink.png')}')`,
        background: "rgb(240 202 186 / 69%)"
      } 
    case "grammar-a":   
      return {
        model: model,
        color:"tl-1 cl-o",
        name:"Grammar", 
        bolal:"bola bdb-o b-l",
        bolar:"bola bdb-o b-r",
        cordar:"corda bd-o cr-r",
        cordal:"corda bd-o cr-l",
        icon: readFile('assets/img/nota.png'),
        subtitle: "Complete a frase selecionando a opção correta de cada caixinha.",
        backgroundImage: `url('${readFile('assets/img/backpink.png')}')`,
        background: "rgb(240 202 186 / 69%)"
      }  
    case "writing-a":   
      return {
        model: model,
        color:"tl-1 cl-p", 
        name:"Writing",
        bolal:"bola bdb-p b-l",
        bolar:"bola bdb-p b-r",
        cordar:"corda bd-p cr-r",
        cordal:"corda bd-p cr-l",
        icon: readFile('assets/img/iconwriting.png'),
        subtitle: "Escute o enunciado, e transcreva exatamente o que ouviu. Você pode ouvir novamente quantas vezes precisar, enquanto houver tempo.",
        backgroundImage: `url('${readFile('assets/img/fundoroxo.png')}')`,
        background:"rgb(212 198 234 / 80%)"
      }
      case "writing-b":   
      return {
        model: model,
        color:"tl-1 cl-p", 
        name:"Writing",
        bolal:"bola bdb-p b-l",
        bolar:"bola bdb-p b-r",
        cordar:"corda bd-p cr-r",
        cordal:"corda bd-p cr-l",
        icon: readFile('assets/img/iconwriting.png'),
        subtitle: "Leia o enunciado e responda a pergunta abaixo. Use frases completas em sua resposta.",
        backgroundImage: `url('${readFile('assets/img/fundoroxo.png')}')`,
        background:"rgb(212 198 234 / 80%)"
      } 
      case "speaking-a":   
      return {
        model: model,
        color:"tl-1 cl-g1", 
        name:"Speaking",
        bolal:"bola bdb-g b-l",
        bolar:"bola bdb-g b-r",
        cordar:"corda bd-g cr-r",
        cordal:"corda bd-g cr-l",
        icon: readFile('assets/img/iconspeaking.png'),
        subtitle: "Escute o áudio e selecione a alternativa que melhor condiz ao enunciado. Então, grave sua pronúncia lendo em voz alta a alternativa selecionada. Você pode ouvir o enunciado novamente quantas vezes precisar, enquanto houver tempo. Também pode ouvir o que você gravou, e gravar novamente caso esteja insatisfeito com sua resposta.",
        backgroundImage:  `url('${readFile('assets/img/fundoverde.png')}')`,
        background:"rgb(180 233 212 / 48%)"
      } 
      case "speaking-b":   
      return {
        model: model,
        color:"tl-1 cl-g1", 
        name:"Speaking",
        bolal:"bola bdb-g b-l",
        bolar:"bola bdb-g b-r",
        cordar:"corda bd-g cr-r",
        cordal:"corda bd-g cr-l",
        icon: readFile('assets/img/iconspeaking.png'),
        subtitle: "Grave sua pronúncia lendo em voz alta a frase abaixo, mesmo que seja um pergunta. Você pode ouvir o que você gravou, e gravar novamente caso esteja insatisfeito com sua resposta.",
        backgroundImage: `url('${readFile('assets/img/fundoverde.png')}')`,
        background:"rgb(180 233 212 / 48%)"
      } 
    default:
      return
  }
}
