import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { extractSlug } from '../services/slug'

const link = extractSlug()

function MicDenied() {
  const [havePermissions, setHavePermissions] = useState(false)

  const checkPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    permissions.then((stream) => {
      alert('accepted the permissions')
      setHavePermissions(true)
      /*
      this.setState(((prevState) => {
        havePermissions: !prevState.havePermissions
      }))
      */
    })
    .catch((err) => {
      setHavePermissions(false)
      console.error(`${err.name} : ${err.message}`)
    })
  }

  return (
    <>
      <div className="d-flex justify-content-center mt-3">
        <button onClick={checkPermissions} className={`btn-big gradient-${havePermissions === true ? 'green' : 'orange'}`}>
          Permissions button
        </button>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Link to={`/${link}`}>Início</Link>
      </div>
    </>
  ) 
}

export default MicDenied