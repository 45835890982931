import { Api as axios } from '../api'
import { getCurrentUser } from '../services/isLoggedIn'

const baseURL = `classrooms`

export const classroomAction = async (action, param, object) => {
  const user = getCurrentUser() || param

  const data = { 
    user : { 
      profile: user?.profile, 
      school: {
        _id: user?.school?._id 
      }, 
      user: user?._id 
    }
  }

  switch(action) {
    case 'GET':
      if(user?.profile === 'SCHOOL_ADMIN') {
        const get = await axios.get(`${baseURL}/getBySchool/${user.school._id}`)
        return get.data
      }
      else if (user?.profile === 'COACH') { 
        const get = await axios.post(`${baseURL}/getByCoach/`, { school: user.school._id, user: user._id }) 
        return get.data
      }
      else {
        const get = await axios.get(`${baseURL}/get`)
        return get.data
      }

    case 'GETBYSCHOOL':
      const getBySchool = await axios.get(`${baseURL}/getBySchool/${param}`)      
      return getBySchool.data

    case 'GETCOMBO':  
      const comboSchool = await axios.post(`${baseURL}/getCombo`, data)        
      return comboSchool.data

    case 'GETBYID': 
      const getID = await axios.get(`${baseURL}/get/${param}`)
      return getID.data   
    
    case 'GETBYUSER':
      const get = await axios.post(`${baseURL}/postByUser/`, { school: param.school._id, user: param._id }) 
      return get.data

    case 'UPDATE':
      const getupdate = await axios.post(`${baseURL}/update/${param}`, object)
      return getupdate.data
    
    case 'ADD':
      const add = await axios.post(`${baseURL}/add`, param)
      return add.data
          
    default:
      return
  }
}

export const getClassroom = async(id) => {
  return classroomAction('GETBYID', id)
}

export const getStudentClassroom = async (user) => {  
  const classroom = await classroomAction('GETBYSCHOOL', user.school._id)
     
  const classr = classroom.data.filter(f => {
    for(let i=0; i<f.users.length; i++) {
      if((f.users[i] === user._id))
        return f
    }
  })
  return classr.length > 0 && classr[0]._id
}
