import React, { Component } from 'react'
import { Api as axios } from '../api'
import Countdown from './countdown'
import PTDragDrop from './pt-dragdrop'
import Button from './send'
import { saveTest } from '../services/test'

export default class PTGrammarB extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitting: false,
      isEmpty: true,
      name: '',
      order: 0,
      sentence_type: '',
      sentence: '',
      choices: [],
      values: '',
      chuncks: [],
      timer: 0,
      level: '',
      ptmodel: '',
      modelname: '',
      grammar: '',
      test: []
    }
  }

  componentDidMount() {
    axios
      .get(`questions/${this.props.id}`)
      .then(response => {
        const { data } = response
        this.setState({
          ptmodel: data.ptmodel,
          name: data.name,
          order: data.order,
          modelname: data.modelname,
          level: data.level,
          timer: data.timer,
          sentence_type: data.sentence_type,
          sentence: data.sentence,
          choices: data.choices,
          grammar: data.grammar,
          chuncks: data.chuncks
        })
      })
      .catch(function (error) { 
        if (error.response?.status === 404) window.location.href ='/404'
        console.error(error) 
      })
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({ 
      [e.target.name]: e.target.value, 
      isEmpty: false 
    })
  }

  updateChuncks = (pos, value) => {
    const { chuncks } = this.state
    chuncks[pos].answer = value
    // const id = "ch_" + chuncks[pos].position
    this.setState({ chuncks })  
  }

  onSubmit = async (e) => e.preventDefault()

  onSave = async () => {
    const { choices } = this.state
    const redirect = await saveTest(this, 'Grammar', choices)
    if (redirect) window.location.href = redirect
  }

  onSave = async () => {
    const { submitting, choices } = this.state
    if (submitting) return
    this.setState({ submitting: true }, async () => {
      const redirect = await saveTest(this, 'Grammar', choices)
      if (redirect) window.location.href = redirect
    })
  }

  render() {
    const { sentence, values, choices, chuncks, timer, isEmpty } = this.state

    return (
      <div>
        <Countdown
          questionId={this.props.id}
          timer={timer}
          color="toptimer white t-blue"
          colortext="bottimer txt-blue"
          next={this.onSave}
        />
        
        <form className="m-t-20" onSubmit={this.onSubmit}>
          <PTDragDrop 
            sentence={sentence}
            values={values}
            choices={choices}
            chuncks={chuncks}
            updateChuncks={this.updateChuncks}
            onChange={this.onChange}
          />

          <Button
            className="btn-big gradient-orange" 
            onClick={this.onSave}
            isEmpty={isEmpty}
          />
        </form>
      </div>
    )
  }
}
