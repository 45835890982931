import React, { Component } from 'react'
import Structure from './site-structure'
import MicRecorder from 'mic-recorder-to-mp3'
import { Link } from 'react-router-dom'
import Recording from '../components/Recording'
import { getLocalStream } from '../utils/media'
import { readFile } from '../api'

const Mp3Recorder = new MicRecorder({ bitRate: 128 })
export default class TesteDispositivo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      values: '',
      stop: 'none',
      play: 'block',
      player: 'none',
      blob: null
    }
  }

  componentDidMount() {
    document.body.style.backgroundImage = `url('${readFile('assets/img/fundoverde.png')}')`
    document.body.style.background = "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #7DE4C3 120.65%);"

    getLocalStream()
  }

  start = () => {
    const { isBlocked } = this.state
    if (isBlocked) {
      alert('Permission to use microphone denied.')
      return null
    } 
    Mp3Recorder
      .start()
      .then(() => {
        this.setState({ isRecording: true }, () => {
          const fadee = document.getElementById('fadee')
          if(fadee) fadee.className = 'fade opacity modal-backdrop show'
          this.controlvisibility('none', 'block')
        })
      })
      .catch(console.error)
  }

  stop = () => {
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        this.controlvisibility('block', 'none')
        const fadee = document.getElementById('fadee')
        if(fadee) fadee.className = ''
        this.setState({ blob }, () => {
          const file = new File(buffer, 'audio.mp3', {
            type: blob.type,
            lastModified: Date.now()
          })
          const blobURL = URL.createObjectURL(file)
          this.setState({ blobURL, isRecording: false })
        })
      })
      .catch(console.error)
  }

  controlvisibility = (play, stop) => this.setState({ play, stop, player: play })

  render() {
    const { isRecording, play, stop, player, blobURL } = this.state
    const { match } = this.props
    const { slug } = match.params
    
    return (
      <Structure>
        <div className="text-center">
          <div className="card sha max-8 m-t-40">
            <div className=" bord-green card-body ">
              <img className="w-70" alt="" src={readFile('assets/img/aqnota.png')} />
                <div className="mt3">
                  <div className="col-sm m-auto tstop cstop-g font36">
                    vamos testar o seu microfone
                  </div>
                  <div className="col-sm pt-3 m-auto st3 font-16">
                    Clique para iniciar a gravação, fale, e clique novamente para enviar. Preparado?
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-11 m-auto">
                    <div className=" d-flex m-t-40">
                      <div onClick={this.start} className={" mic-b " + play}>
                        <svg width="5em" height="7em" viewBox="0 0 16 16" className="bi bi-mic-fill mic" fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
                          <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
                          <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                        </svg>
                      </div>
                      <div onClick={this.stop} className={" " + stop}>
                        <Recording isRecording={isRecording} onFinished={this.stop} />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className={`row justify-content-center ${player}`}>
                  <audio id="recording" src={blobURL} controls />
                </div>

                <div className="d-flex justify-content-center bd-highlight ">
                  <Link 
                    to={`/${slug}/warmup/1`} 
                    className="btn-big gradient-green m-t-40"
                  >
                    NEXT
                  </Link>
                </div>                  

                  <img
                    alt=""
                    className="imggirl"
                    src={readFile('assets/img/aqgirl.png')}
                  />
                  <img
                    alt=""
                    className="imgboy"
                    src={readFile('assets/img/aqboy.png')}
                  />
              </div>
          </div>
          <div className="lousa"></div>
          <div className="lousa1 m-auto"></div>
        </div>
      </Structure>
    )
  }
}
