import React, { Component } from 'react'
import PTImages from './pt-images'
import { adminConfig, Api as axios } from '../api'
import PTQuestion from './pt-question'
import { saveTest } from '../services/test'
import Countdown from './countdown'
import Button from './send'

export default class PTReadingA extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitting: false,
      isEmpty: true,
      choices: [],
      values: '',
    }
  }

  componentDidMount() {
    axios.get(`questions/${this.props.id}`)
      .then((response) => {
        const { data } = response
          const [files] = data.choices
          const newfiles = []

          for (let i = 0; i < files.optionlist.length; i++) {
            const img = adminConfig().root + "/ra/" + files.optionlist[i]
            newfiles.push({ 
              blob: img, 
              name: files.optionlist[i], 
              file: null 
            })
          }

          this.setState({
            ptmodel: data.ptmodel,
            name: data.name,
            order: data.order,
            modelname: data.modelname,
            level: data.level,
            timer: data.timer,
            sentence_type: data.sentence_type,
            sentence: data.sentence,
            choices: data.choices,
            grammar: data.grammar,
            files: newfiles
          })
      })
      .catch(function (error) { 
        if (error.response?.status === 404) window.location.href ='/404'
        console.error(error) 
      })   
  }

  onChangeValues = (values) => this.setState({ values, isEmpty: false })

  onSave = async () => {
    const { submitting, values } = this.state
    if (submitting) return
    this.setState({ submitting: true }, async () => {
      const redirect = await saveTest(this, 'Reading', values)
      if (redirect) window.location.href = redirect
    })
  }

  onSubmit = (e) => e.preventDefault()

  render() {
    const { timer, sentence, files, values, isEmpty } = this.state
    return (      
      <form className="text-center" onSubmit={this.onSubmit}>
        <Countdown 
          questionId={this.props.id}
          timer={timer} 
          color="toptimer white t-orange" 
          colortext="bottimer txt-orange" 
          next={this.onSave}
        /> 
        
        <PTQuestion sentence={sentence} />

        <PTImages 
          class="b-orange" 
          files={files} 
          values={values} 
          onChangeValues={this.onChangeValues} 
        />

        <Button 
          className="btn-big gradient-red" 
          onClick={this.onSave} 
          isEmpty={isEmpty} 
        />
      </form>
    )
  }
}
