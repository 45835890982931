  import React, { Component } from 'react'
  import { adminConfig, Api as axios } from '../api'
  import Button from './send'
  import { saveTest } from '../services/test'
  import Countdown from './countdown'
  import QuestionBack from './questionback'

  export default class PTWritingB extends Component {
    constructor(props) {
      super(props)

      this.state = {
        submitting: false,
        isEmpty: true,
        choices: [{ word: '', optionlist: [] }],
        value: '',
      }
    }

  componentDidMount() {
    axios.get(`questions/${this.props.id}`)
      .then((response) => {
        const { data } = response
          this.setState({
            ptmodel: data.ptmodel,
            name: data.name,
            order: data.order,
            modelname: data.modelname,
            level: data.level,
            timer: data.timer,
            sentence_type: data.sentence_type,
            sentence: data.sentence,
            choices: data.choices,
            grammar: data.grammar,
            background: data.background
      })
    })
    .catch(function (error) { 
      if (error.response?.status === 404) window.location.href ='/404'
      console.error(error) 
    })
  }
  onChange = (e) => {
    e.preventDefault()
    this.setState({ 
      [e.target.name]: e.target.value, 
      isEmpty: false 
    })
  }

  correctAnswer = (values) => this.setState({ values })
  
  onSave = async () => {
    const { submitting } = this.state
    if (submitting) return
    this.setState({ submitting: true }, async () => {
      const redirect = await saveTest(this, 'Writing')
      if (redirect) window.location.href = redirect
    })
  }

  onSubmit = (e) => e.preventDefault()

  render() {
    const { timer, sentence, sentence_type, choices, value, background, isEmpty } = this.state
    const backgroundColor = background && background !== '' && adminConfig().root + "/back/wb/" + background

    return (    
      <form className="text-center" onSubmit={this.onSubmit}>
        <Countdown 
          questionId={this.props.id}
          timer={timer} 
          color="toptimer white t-purple" 
          colortext="bottimer txt-purple" 
          next={this.onSave}
        />

        <div className="m-t-40 cl-p">
            <b>{sentence_type}</b>
        </div>
        <div className="st2 wr">{sentence}</div>
        <div className="row m-t-40">
            <div className="col-sm-7 st4 m-auto p-2">
              1. {choices[0].word}
            </div>
        </div>
        <div className="row">
            <div className="col-sm-7 m-auto">
                <div className="form-group">
                    <input name="value" className="form-control purple-shadow" value={value} onChange={this.onChange} ></input>
                </div>
            </div>
        </div>

        <Button 
          className="btn-big gradient-purple" 
          onClick={this.onSave}
          isEmpty={isEmpty} 
        />

        <QuestionBack src={backgroundColor}/>  
      </form>
    )
  }
}
