import React from 'react'

export const load = () => {
  const spinn = document.getElementById('load')
  if (spinn) {
    spinn.style.display = (spinn?.style?.display === 'block') ? 'none' : 'block'
  }
}

function Load({ display = 'none' }) {
  return(
    <div id="load" className={display}>
      <div className="loadfade">
        <div className="loading-spinner"></div>
      </div>
    </div>
  )
}
export default Load