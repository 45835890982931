import React, { useEffect, useState } from 'react'
import RecordingWrapper from '../../components-pt/recording'
import './Recording.css'

let interval;

function Recording ({ isRecording, timer = 15, onFinished }) {
  if (!isRecording) return <div></div>
  const [timeLeft, setTimeLeft] = useState(timer)
  
  useEffect(() =>{    
    if (!timeLeft || timeLeft < 0) {
      if (onFinished) onFinished()
      clearInterval(interval)
      return
    }
  }, [timeLeft])
  
  useEffect(() => {
    interval = setInterval(() => setTimeLeft((timeLeft) => timeLeft - 1), 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div id="fadee">
      <RecordingWrapper type="bars" color="blueviolet" />
      <h1 className="m-t-xl stop txt-up bold text-center text-purple">RECORDING</h1>
      <h3 className="stop txt-up bold text-center text-purple">{timeLeft} sec</h3>

      <h5>Clique na tela se quiser encerrar a gravação antes do tempo!</h5>
    </div>
  )
}

export default Recording