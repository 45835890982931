/* eslint-disable no-undef */
import React from 'react'
import Structure from './site-structure'
import Header from './header'
import { actionMenu } from '../services/menu'
import { apiURL, readFile } from '../api'

const showCertificate = false

const LEVELS = {
  'A1': 0,
  'A2': 1,
  'B1': 2,
  'B2': 3,
  'C1': 4,
  'C2': 5,
}

const ASSET_URL = readFile('assets')

const StudentResultBase = ({ test }) => {
  if (!test || test.length === 0) return <div></div>

  const certificatePDF = () => {
    const [item] = test
    const { testID } = item
    window.open(`${apiURL()}certificates/${testID}`)
  }

  return (
    <Structure>
      <Header/>
      <div>
        {test.map((item,key1)=>(
          <React.Fragment key={`item-${key1}`}>
            <div className="row m-t-50">
              <div className="col-sm-11 m-auto">
                <div className="text-center m-t-20">
                    <h1 className="tblue f-p font-48 t1 result"> Parabéns!<br/> Você concluiu o Flex Assessment. </h1>
                    <label className="start col-sm-11 ">{item.name}, confira os seus resultados e a suas notas. </label>
                </div>
              </div>
            </div>
            <div className="row m-b-30 m-t-20">
              <div className="col-md-8 m-auto">
                <div className="card qcard">
                  <div className="card-body text-center">
                    <img className="imgpdf" alt="" src={`${ASSET_URL}/img/medalr.png`} />
                    <div className="tblue t1 font-28 tm">{item.finalGrade}</div>                            
                    <div className="m-auto d-flex box-card-result">
                      <h5 className="b-results red-end-A1 font-20 f-p" style={{ width: '90px', display: 'inline-flex' }}>Pre A1</h5>
                      <div className="dot"></div>
                      <h5 className="br-r red-end-A1 f-p">A1</h5>
                      <div className="dot"></div>
                      <h5 className="br-r red-end-A1 f-p">A1+</h5>
                      <div className="dot"></div>
                      <h5 className="yellow-end f-p">B1</h5>
                      <div className="dot"></div>
                      <h5 className="yellow-end f-p">B1+</h5>
                      <div className="dot"></div>
                      <h5 className="yellow-end f-p">B2</h5>
                      <div className="dot"></div>
                      <h5 className="yellow-end f-p">B2+</h5>
                      <div className="dot"></div>
                      <h5 className="lightg-end f-p">C1</h5>
                      <div className="dot"></div>
                      <h5 className="lightg-end f-p">C1+</h5>
                      <div className="dot"></div>
                      <h5 className="lightg-end  f-p">C2</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-center m-b-30">
              <div className="col-xl-8 m-auto">
                {item.testByAbility.map((item, key) => (
                  <React.Fragment key={`item--test-by-ability-${key}`}>           
                    <div className="col-xl-12 m-auto ">  
                      <div className="m-2 col-xl-12 text-center txt-up tblue">
                        {item.testType}   
                      </div> 
                      <img alt="" className="imgicon" src={`${ASSET_URL}/img/${item.testType}pdf.png`} />
                      <span id={"lbl_" + key + key1} onClick={() => actionMenu("idx_" + key + key1 )} className="float-right m-t-20 under phide"> 
                        Level ({item.level}) - {parseFloat(item.average_weight || 0).toFixed(2)} %  
                      </span>     
                      <span id={"lbl_" + key + key1 } onClick={() => actionMenu("idx_" + key + key1 )} className="float-right m-t-20 under none pshow">
                        <img alt="" className="ml-2 imgs" src={`${ASSET_URL}/img/nav.png`} />
                      </span>
                      
                      {[...Array(5).keys()].map(index => (
                        <img 
                          key={`${item.testType}-${item.level}-${index}`}
                          alt="" 
                          className={`ml-${index === 0 ? 2 : 1} imgs`}
                          src={`${ASSET_URL}/img/${index <= LEVELS[item.level] ? 'sazul' : 'sgray'}.png`}
                        />
                      ))}
                    </div>                                             
                    <div id={"idx_" + key + key1} className="none card rsha col-md-11 m-t-20">                             
                      <div className="d-flex text-center justify-content-center m-b-20">
                        <div className="mt-2 mr-4 font-18 grid"> Total Hits
                          <div className="text-green bold font-18 grid"> {item.detail_results?.correct_count || 0} </div>
                        </div>
                        <div className="mt-2 ml-4 font-18 grid"> Total Mistakes
                          <div className="text-red bold font-18 grid"> {item.detail_results?.mistakes_count || 0} </div>
                        </div>
                      </div>
                      <div className="row nwrap  justify-content-center">
                        <div className="bl tab2 bt col-sm-2">Level</div>
                        <div className="bl tab2 bt col-sm-7">Question </div>
                        <div className="bl tab2 bt col-sm-3">Status</div> 
                      </div>
                      {item.questions.map((question, key) => (
                        <div className="row nwrap  f-5 justify-content-center" key={`item--questions-${key}`}>
                          <div className="bl tab2  text-center col-sm-2 d-flex  align-items-center ">
                            <span className="m-auto">{question.level}</span>
                          </div>
                          <div className="bl tab2 f-r col-sm-7 d-flex  align-items-center"> {question.grammar}</div>
                          <div className="bl  tab2 text-center col-sm-3 d-flex  align-items-center">{question.iscorrect === true ?  <span className="botaobag grid m-auto">CORRECT</span>: <span className="bag grid m-auto">INCORRECT</span> }</div>
                        </div> 
                      ))}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>  
          </React.Fragment>
        ))}

        {showCertificate && (
          <div className="row m-t-50 justify-content-center">
            <button
              type="button"
              id="pdf"
              className="btn-big grad-mains " 
              onClick={() => certificatePDF()}
            > 
                SALVAR CERTIFICADO 
            </button>
          </div>
        )}
      </div>
    </Structure>
  )
}

export default StudentResultBase