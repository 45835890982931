import { Api as axios } from '../api'

const baseURL = `report`

export const reportAction = async(action, param, object) => {

  switch(action) {
    case 'GETREPORTBYUSER': 
      const reportByUser = await axios.get(`${baseURL}/getReportByUser/${param}`)
      return reportByUser.data

    case 'GETREPORTPROFILE':
      const reportProfile = await axios.post(`${baseURL}/getReportProfile/${param}`, object)     
      return reportProfile.data
    default:
      return
  }
}
